<template>
    <div class="pb-5">
        <div class="mt-2 mb-3 container-header">
            <!-- <div class="d-flex align-items-center justify-content-between mb-1">
                <div>
                    <div class="">
                        <p class="m-0">Mostrando 1 a 10 de 14 registros</p>
                    </div>
                </div>
                <div></div>
            </div> -->
            <div class="container-body-header">
                <div class="d-flex align-items-center">
                    <p
                        class="page-btn"
                        :class="[pageActive == 1 ? 'disabled' : null]"
                        @click="changePage(pageIn - 1)"
                    >
                        <feather-icon icon="ChevronLeftIcon" size="20" />
                    </p>
                    <div class="container-btns">
                        <p
                            @click="changePage(num)"
                            class="page-btn"
                            :class="[pageActive == num ? 'active' : null]"
                            v-for="(num, index) in pages"
                            :key="index"
                        >
                            {{ num }}
                        </p>
                    </div>
                    <p
                        class="page-btn"
                        :class="[pageActive == totalPages ? 'disabled' : null]"
                        @click="changePage(pageIn + 1)"
                    >
                        <feather-icon icon="ChevronRightIcon" size="20" />
                    </p>
                </div>
                <div
                    class="d-flex align-items-center justify-content-center mt-2 mt-md-0"
                >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Buscar enfermedad"
                        v-model="campo"
                        v-on:keyup.enter="keyEnter"
                    />
                    <div
                        class="d-flex align-items-center justify-content-end ml-2"
                    >
                        <AddDisease @refresh="init" />
                    </div>
                </div>
            </div>
        </div>

        <div class="m-auto card content-table">
            <div class="container-table-coliseum header-table">
                <p>#</p>
                <p>Nombre</p>
                <p>Creado por</p>
                <p>Status</p>
                <p>Opciones</p>
            </div>
            <div
                class="container-table-coliseum body-table"
                v-for="(item, index) in data"
                :key="index"
            >
                <div
                    class="d-flex align-items-center justify-content-center body-index"
                >
                    <p>{{ index + 1 }}</p>
                </div>

                <div
                    class="d-flex flex-column align-items-center justify-content-center body-cell"
                >
                    <p>{{ item.name }}</p>
                    <span class="font-other-name">{{ item.other_name }}</span>
                </div>

                <div
                    class="d-flex flex-column align-items-center justify-content-center body-cell"
                >
                    <p>{{ item.created_user.name }}</p>
                    <span>{{ item.created_user.date | myGlobalDay }}</span>
                </div>

                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <v-select
                        :id="`${item.id}-id-select`"
                        v-model="item.status"
                        :options="statusArray"
                        :clearable="false"
                        transition=""
                        placeholder="Elija el ejemplar"
                        @input="toggleStatus(item.id, item.status)"
                        :reduce="option => option.id"
                    >
                        <template #option="{ label, color }">
                            <div
                                class="selected d-center d-flex align-items-center"
                            >
                                <div
                                    class="circle"
                                    :style="{ background: color }"
                                ></div>
                                <p>{{ label }}</p>
                            </div>
                        </template>

                        <template #selected-option="{ label,color }">
                            <div
                                class="selected d-center d-flex align-items-center"
                            >
                                <div
                                    class="circle"
                                    :style="{ background: color }"
                                ></div>
                                <p>{{ label }}</p>
                            </div>
                        </template>
                    </v-select>
                </div>
                <div
                    class="d-flex align-items-center justify-content-center body-cell"
                >
                    <feather-icon
                        class="icon cursor-pointer"
                        icon="EditIcon"
                        :size="width < 600 ? '18' : '24'"
                        @click="
                            itemDisease = item;
                            openModalEditDisease = true;
                        "
                    />
                    <feather-icon
                        @click="deleteParticipant(item.id)"
                        class="icon text-danger"
                        icon="TrashIcon"
                        :size="width < 600 ? '18' : '24'"
                    />
                </div>
            </div>
        </div>

        <EditDisease
            v-if="itemDisease"
            :item="itemDisease"
            :openModalEditDisease="openModalEditDisease"
            @refresh="refresh"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import {
    getDiseasesToStatus,
    updateToggleStatus,
    deleteDiseaseById
} from "./diseases.service";
import vSelect from "vue-select";
import AddDisease from "./components/AddDisease.vue";
import EditDisease from "./components/EditDisease.vue";

export default {
    name: "ListParticipantsBrain",
    components: { vSelect, AddDisease, EditDisease },
    data() {
        return {
            data: [],
            width: this.$store.state["app"].windowWidth,
            pageIn: 1,
            perPageIn: 10,
            campo: null,
            pages: [],
            pageActive: 1,
            totalPages: null,
            openModal: false,
            statusArray: [
                {
                    id: 1,
                    label: "Inactivo",
                    color: "#ea5455"
                },
                {
                    id: 2,
                    label: "Pendiente",
                    color: "#00d0e7"
                },
                {
                    id: 3,
                    label: "Activo",
                    color: "#28c66f"
                }
            ],
            itemDisease: null,
            openModalEditDisease: false
        };
    },
    computed: {
        ...mapState("auth", ["currentUser"])
    },
    async mounted() {
        // this.isPreloading(true);
        
        // this.isPreloading(true);
        await this.init();

        // setTimeout(()=> {
        //     this.isPreloading(false)
        // },1000)
    },
    methods: {
        refresh() {
            this.init();
            this.openModalEditDisease = false;
            this.itemDisease = null;
        },
        async init() {
            const {
                data: { data, last_page, current_page }
            } = await getDiseasesToStatus({
                status: 0,
                campo: this.campo,
                pageIn: this.pageIn,
                perPageIn: this.perPageIn
            });
            this.pages = this.createPages(last_page);
            this.pageActive = current_page;
            this.totalPages = last_page;
            this.data = data.map(item => {
                return {
                    ...item,
                    created_user: JSON.parse(item.created_user)
                };
            });
        },
        keyEnter() {
            this.pageIn = 1;
            this.init();
        },
        createPages(num) {
            let pages = [];
            for (let i = 1; i <= num; i++) {
                pages.push(i);
            }
            return pages;
        },
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.pageIn = page;
                this.init();
            }
        },
        async toggleStatus(id_participant, status) {
            const { data } = await updateToggleStatus({
                status,
                id: id_participant
            });
            await this.showSuccessToast(
                data,
                "success",
                "top-left",
                "Enfermedad actualizada",
                "CheckIcon"
            );
            await this.init();
        },
        async deleteParticipant(id) {
            const swal = await this.showConfirmSwal();
            if (swal.isConfirmed) {
                const {
                    data: { ok, msg }
                } = await deleteDiseaseById({
                    user_id: this.currentUser.user_id,
                    id
                });
                await this.init();
                await this.showSuccessToast(
                    msg,
                    "success",
                    "top-left",
                    "Enfermedad actualizada",
                    "CheckIcon"
                );
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.container-header {
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    transition: 0.1s all ease-in-out;
    @media screen and (min-width: 700px) {
        padding: 1rem;
    }
    .page-btn {
        height: 32px;
        width: 32px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s all ease-in-out;
        border-radius: 32px;
        cursor: pointer;
        background: #f3f2f7;
    }
    .container-btns {
        margin: 0 0.4rem;
        width: auto;
        background: #f3f2f7;
        height: 32px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        transition: 0.1s all ease-in-out;
        .active {
            background: #7367f0;
            color: #fff;
        }
    }
    .disabled {
        color: #d5d5d5;
    }
}
.dark-layout {
    .container-header {
        background: #283046;
        .page-btn {
            background: #242b3d;
        }
        .container-btns {
            background: #242b3d;
            .active {
                background: #7367f0;
                color: #fff;
            }
        }
    }
}
.content-table {
    min-width: 100%;
    background: #fff;

    border-radius: 10px;
    overflow-x: scroll;
    overflow-y: visible;
    @media screen and (min-width: 1000px) {
        overflow-x: visible;
        overflow-y: visible;
    }
    .container-table-coliseum {
        min-width: 800px;
        padding: 0.5rem 1rem;
        display: grid;
        grid-template-columns: 1fr 4fr 4fr 4fr 4fr;
        text-align: center;
        @media screen and (min-width: 600px) {
            padding: 1rem 2rem;
        }

        p {
            margin: 0;
            font-size: 12px;
            transition: 0.1s all ease-in-out;
            @media screen and (min-width: 600px) {
                font-size: 14px;
            }
        }
        .icon {
            //color: red;
            margin: 0 0.2rem;
            cursor: pointer;
        }
    }
    .header-table {
        transition: 0.1s all ease-in-out;
        background: #f3f2f7;
        text-align: center;
        p {
            font-weight: 600;
        }
    }
    .body-table {
        border-bottom: 0.5px solid #e6e6e6;
        transition: 0.1s all ease-in-out;
        &:last-child {
            border-bottom: none;
        }
        .body-index {
            min-width: 30.88px;
        }
        .body-cell {
            min-width: 123.52px;
            p {
                word-wrap: break-word;
            }
            .badge {
                width: 80%;
                @media screen and (min-width: 600px) {
                    width: 50% !important;
                }
            }
        }
    }
}
.dark-layout {
    .content-table {
        background: #283046;
        .header-table {
            background: #343d55;
        }
        .body-table {
            border-bottom-color: #3b4253;
        }
    }
}
.btn-agregar-organizer {
    width: 180px;
}
.circle {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 4px;
}
.container-body-header {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 700px) {
        flex-direction: row;
        justify-content: space-between;
    }
}
.font-other-name {
    font-size: 12px;
    font-weight: bold;
}
</style>
