<template>
    <div>
        <button
            class="btn btn-primary btn-agregar-participante "
            @click="openModal = true"
        >
            Agregar Enfermedad
        </button>
        <b-modal
            @ok.prevent="saveDis"
            @hidden="closeModal"
            title="Agregar participante"
            v-model="openModal"
        >
            <validation-observer tag="b-row" ref="form">
                <b-col cols="12">
                    <validation-provider
                        rules="required"
                        v-slot="{ errors, valid }"
                    >
                        <b-form-group
                            label="Nombre de enfermedad"
                            label-for="name"
                        >
                            <b-form-input
                                placeholder="Nombre de enfermedad"
                                v-model="name"
                                :state="errors[0] ? false : valid ? true : null"
                                @input="val => (name = name.toUpperCase())"
                            />
                            <span style="color: red">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>

                <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-end"
                >
                    <div class="d-flex align-items-center cursor-pointer" @click="addNameOther = !addNameOther">
                        <p class="mb-0 add-phone font-small-2">
                            {{ !addNameOther ? 'Agregar nombre alternativo' : 'Eliminar nombre alternativo' }}
                        </p>
                        <feather-icon :class="[ !addNameOther ? 'icon-add' : 'icon-add add' ]" icon="PlusIcon" size="18" />
                    </div>
                </b-col>

                <b-col cols="12" v-if="addNameOther">
                    <b-form-group
                        label="Nombre alternativo"
                        label-for="other-name"
                    >
                        <b-form-input
                            placeholder="Nombre alternativo"
                            v-model="otherName"
                            @input="
                                val => (otherName = otherName.toUpperCase())
                            "
                        />
                    </b-form-group>
                </b-col>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import { saveDisease } from "../diseases.service";
import { mapState } from "vuex";

export default {
    name: "AddDisease",
    data() {
        return {
            openModal: false,
            name: "",
            otherName: "",
            errorText: false,
            valid: false,
            addNameOther: false
        };
    },
    computed: {
        ...mapState("auth", ["currentUser"])
    },
    mounted(){
        this.name = null;
        this.otherName = null;
    },
    methods: {
        async saveDis() {
            const success = await this.$refs.form.validate();
            if (success) {
                const {
                    data: { ok, msg }
                } = await saveDisease({
                    name: this.name,
                    otherName: this.addNameOther ? this.otherName : null,
                    id_user: this.currentUser.user_id
                });
                if (ok) {
                    await this.showSuccessToast(
                        msg,
                        "success",
                        "top-left",
                        "Enfermedad creada",
                        "CheckIcon"
                    );
                    this.openModal = false;
                    this.name = null;
                } else {
                    await this.showSuccessToast(
                        msg,
                        "warning    ",
                        "top-left",
                        "Información",
                        "InfoIcon"
                    );
                }
                this.$emit("refresh");
            } else {
            }
        },
        closeModal() {
            this.name = null;
            this.otherName = null;
            this.addNameOther = false;
        }
    },
    watch: {
        valid() {
            if (this.name.length > 3) {
                this.valid = true;
            } else {
                this.valid - false;
            }
        }
    }
};
</script>

<style lang="scss" scope>
.btn-agregar-participante {
    width: 150px !important;
    font-size: 10px;
    @media screen and (min-width: 600px) {
        width: 200px !important;
        font-size: 14px;
    }
}
.error-input {
    margin-bottom: 0;
    color: red;
    font-size: 12px;
    margin-top: 2px;
}
.icon-add{
    transition: .3s all ease-in-out;
}
.add{
    transform: rotate(45deg);
}
</style>
